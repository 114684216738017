/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();
                const formPopup = document.querySelector('.form-popup');
                const formTrigger = document.querySelectorAll('.form-trigger');
                const departureForm = document.querySelectorAll('.departure-form');

                formTrigger.forEach(function(trigger) {
                  trigger.addEventListener('click', function(e) {
                    if (formPopup) {
                      $.fancybox.open({
                        autoFocus: false,
                        src: '.form-popup',
                        type: 'inline',
                      });
                    }
                  });
                });

                departureForm.forEach(function(form) {
                  form.addEventListener('submit', function(e) {
                    e.preventDefault();
                    var departurefield = document.querySelector('.departure-field input');

                    if(departurefield) {
                      departurefield.value = e.target.departure.value;
                    }

                    if (formPopup) {
                      $.fancybox.open({
                        autoFocus: false,
                        src: '.form-popup',
                        type: 'inline',
                      });
                    }
                  });
                });

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function () {
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('external').attr('target', '_blank');

                // Mobile nav toggle
                $('.c-toggle--nav').click(function (e) {
                    e.preventDefault();

                    $('.c-header').toggleClass('is-open');
                });

                console.log(Cookies);

                // Logo shrink
                var elHeader = $('.c-header');

                if (viewportWidth < 1024) {
                    $(window).scroll(function () {
                        var scrollX = $(this).scrollTop();

                        if (scrollX >= 10) {
                            $(elHeader).addClass('is-scrolling');
                        } else {
                            $(elHeader).removeClass('is-scrolling');
                        }
                    });
                }

                // Header state
                elHeader.hover(function () {
                    $(this).addClass('is-active');
                }, function () {
                    $(this).removeClass('is-active');
                });

                // Sticky menu background color
                var elWrap = $('.o-wrap');

                $(window).scroll(function () {
                    var scrollX = $(this).scrollTop();

                    if (scrollX >= 10) {
                        $(elWrap).addClass('is-scrolling');
                    } else {
                        $(elWrap).removeClass('is-scrolling');
                    }
                });

                // Hero background
                var elHero = $('.c-hero'),
                    heroMobile = $(elHero).data('mobile'),
                    heroDesktop = $(elHero).data('desktop');

                if (elHero.length) {
                    if (viewportWidth >= 1024) {
                        $(elHero).css('background-image', 'url(' + heroDesktop + ')');
                    } else {
                        $(elHero).css('background-image', 'url(' + heroMobile + ')');
                    }
                }

                // Column background
                var columnBackground = $('.o-col-background');

                $(columnBackground).each(function () {
                    var background = $(this).data('background'),
                        placeholder = '/app/uploads/2016/12/w960x335.png';

                    if (background) {
                        $(this).css('background-image', 'url(' + background + ')');
                    } else {
                        $(this).css('background-image', 'url(' + placeholder + ')');
                    }
                });

                if ($('.o-video').length) {
                    // Find all YouTube videos
                    var $allVideos = $("iframe[src^='https://www.youtube.com']"),

                        // The element that is fluid width
                        $fluidEl = $(".o-video");

                    // Figure out and save aspect ratio for each video
                    $allVideos.each(function () {

                        $(this)
                            .data('aspectRatio', this.height / this.width)

                            // and remove the hard coded width/height
                            .removeAttr('height')
                            .removeAttr('width');

                    });

                    // When the window is resized
                    $(window).resize(function () {

                        var newWidth = $fluidEl.width();

                        // Resize all videos according to their own aspect ratio
                        $allVideos.each(function () {

                            var $el = $(this);
                            $el
                                .width(newWidth)
                                .height(newWidth * $el.data('aspectRatio'));

                        });

                        // Kick off one resize to fix all videos on page load
                    }).resize();
                }
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                var fleetSection = $('.c-section--fleet'),
                    fleetSectionBottom = fleetSection.offset().top + fleetSection.outerHeight(true),
                    jetImage = $('.parallax-image');

                // JavaScript to be fired on the home page, after the init JS
                $(window).scroll(function () {
                    var scrollY = $(this).scrollTop();

                    if (scrollY > (fleetSection.offset().top - $(this).height() - 300) && scrollY < fleetSectionBottom) {
                        jetImage.addClass('is-visible');
                    }
                });
            }
        },
        // Request a Quote
        'page_id_84': {
            init: function () {
                // JavaScript to be fired on the about us page
                var elHeader = $('.c-header'),
                    headerHeight = elHeader.outerHeight(true);

                $('body').css('padding-top', headerHeight);

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
